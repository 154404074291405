import { createStore } from 'vuex'

export default createStore({
  state: {
    historyLevel: 0,  // 页面层级，默认是0
  },
  getters: {

  },
  mutations: {
    setHistoryLevel (state, level) {
      state.historyLevel = level;
    },
  },
  actions: {
    initPage ({ commit }) {
      // 初始化页面时，设置是否从 App 进入
      commit('setHistoryLevel', 0);  // 初始设置为一级页面
    },
    firstPage ({ commit }) {
      // 初始化页面时，设置是否从 App 进入
      commit('setHistoryLevel', 1);  // 初始设置为一级页面
    },
    secondPage ({ commit }) {
      commit('setHistoryLevel', 2);  // 进入二级页面
    },
  },
  modules: {
  }
})

<template>
  <router-view />
</template>
<script>
export default {
  created () {
    // console.log('app created')
  }
}
</script>
<style lang="scss">
#app {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  color: #1d2129;
  font-size: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  background: #f2f3f7;
}

.home-page {
  height: calc(100vh - 2.875rem);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f2f3f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

.van-nav-bar {
  // color: #fff !important;
  // background: #3975c6 !important;
  // border-bottom: 1px solid #e5e6eb;
  // height: 47px;
}

.van-nav-bar__title {
  // color: #fff !important;
}
.van-nav-bar .van-icon {
  // color: #fff !important;
}

.safe-bottom {
  height: 13rem;
}

.safe-bottom2 {
  height: 6rem;
}

.bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 4.25rem;
  background: #fff;
  z-index: 888;
  // padding-left: 10px;
  // padding-right: 10px;
  padding-left: 28px;
  padding-right: 28px;
  border-top: 1px solid #e5e6eb;
}

.van-search {
  width: 100vw;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem !important;
  .van-search__action {
    line-height: normal !important;
    margin-left: 10px;
    padding: 0;
  }
}

.van-dialog {
  border-radius: 6px !important;
}
.van-dialog__cancel {
  color: #86909c !important;
}
.van-dialog__confirm {
  color: #0096ff !important;
}
.van-button {
  border-radius: 4px !important;
}
.van-stepper--round .van-stepper__minus {
  color: #0096ff !important;
  border: 1px solid #0096ff !important;
}
.van-stepper--round .van-stepper__plus {
  background: #0096ff !important;
}
.van-row .col {
  margin-bottom: 0 !important;
}
.van-tab {
  font-size: 16px !important;
}
.van-tabs__wrap {
  border-bottom: 1px solid #e5e6eb !important;
}
.van-picker__confirm {
  color: #0096ff !important;
}

.van-cell__title {
  font-size: 15px !important;
}
.van-dialog__header {
  padding-bottom: 16px !important;
}
.van-radio__icon .van-icon {
  border: 1px solid #0096ff !important;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies';
import { Toast } from 'vant';
import { Button } from 'vant';
import { Dialog } from 'vant';
import VueClipboard from 'vue-clipboard2'
import globalfunc from './utils/globalfunc';
import '@/assets/font/font.css';

import Vant from 'vant'
import 'vant/lib/index.css';

createApp(App).use(store).use(router).use(Toast).use(Button).use(Dialog).use(globalfunc).use(VueCookies).use(VueClipboard).use(Vant).mount('#app')
